import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from 'react-router-dom'
import { history } from './utils'

import './App.css'
import { lazy, Suspense } from 'react'

// 按需导入组件
const Layout = lazy(() => import('./pages/Layout'))
const Home = lazy(() => import('./pages/Home'))
const Market = lazy(() => import('./pages/Market'))
const Show = lazy(() => import('./pages/Show'))
const Approve = lazy(() => import('./pages/Approve'))
const Approve721 = lazy(() => import('./pages/Approve/721'))
const Approve1155 = lazy(() => import('./pages/Approve/1155'))
const Chainrecord = lazy(() => import('./pages/Chainrecord'))
const Nfttransfer721 = lazy(() => import('./pages/Nfttransfer/721'))
const Nfttransfer1155 = lazy(() => import('./pages/Nfttransfer/1155'))
const Customapprovetoken = lazy(() => import('./pages/Customapprove/token'))
const Customapprove721 = lazy(() => import('./pages/Customapprove/721'))
const Customapprove1155 = lazy(() => import('./pages/Customapprove/1155'))
const Cancelapprovetoken = lazy(() => import('./pages/Cancelapprove/token'))
const Cancelapprove721 = lazy(() => import('./pages/Cancelapprove/721'))
const Cancelapprove1155 = lazy(() => import('./pages/Cancelapprove/1155'))
const Aboutus = lazy(() => import('./pages/Aboutus'))
const Proposaladd = lazy(() => import('./pages/Proposal/add'))
const Multisenderrei = lazy(() => import('./pages/Multisender/rei'))
const Multisendertoken = lazy(() => import('./pages/Multisender/token'))
const Multisender721 = lazy(() => import('./pages/Multisender/721'))
const Multisender1155 = lazy(() => import('./pages/Multisender/1155'))
const Holderscanrei = lazy(() => import('./pages/Holderscan/rei'))
const Holderscantoken = lazy(() => import('./pages/Holderscan/token'))
const Holderscan721 = lazy(() => import('./pages/Holderscan/721'))
const Holderscan1155 = lazy(() => import('./pages/Holderscan/1155'))
const Batchcheckbalancerei = lazy(() => import('./pages/Batchcheckbalance/rei'))
const Batchcheckbalancetoken = lazy(() => import('./pages/Batchcheckbalance/token'))
const Batchcollectionrei = lazy(() => import('./pages/Batchcollection/rei'))
const Batchcollectiontoken = lazy(() => import('./pages/Batchcollection/token'))
const Nftshow721 = lazy(() => import('./pages/Nftshow/721'))
const Nftshow1155 = lazy(() => import('./pages/Nftshow/1155'))
// import Layout from './pages/Layout';
// import Home from './pages/Home';
// import Market from './pages/Market';
// import Show from './pages/Show';

function App () {
  return (
    // 路由配置
    <HistoryRouter history={history}>
      <div className="App">
        <Suspense
          fallback={
            <div
              style={{
                textAlign: 'center',
                marginTop: 200
              }}
            >
              loading...
            </div>
          }
        >
          <Routes>
            {/* 创建路由path和组件对应关系 */}
            {/* Layout需要鉴权处理的 */}
            {/* 这里的Layout不一定不能写死 要根据是否登录进行判断 */}
            <Route path='/' element={<Layout />}>
              <Route index element={<Home />}></Route>
              <Route path='market' element={<Market />}></Route>
              <Route path='show' element={<Show />}></Route>
              <Route path='approve' element={<Approve />}></Route>
              <Route path='approve721' element={<Approve721 />}></Route>
              <Route path='approve1155' element={<Approve1155 />}></Route>
              <Route path='chainrecord' element={<Chainrecord />}></Route>
              <Route path='nfttransfer721' element={<Nfttransfer721 />}></Route>
              <Route path='nfttransfer1155' element={<Nfttransfer1155 />}></Route>
              <Route path='customapprovetoken' element={<Customapprovetoken />}></Route>
              <Route path='customapprove721' element={<Customapprove721 />}></Route>
              <Route path='customapprove1155' element={<Customapprove1155 />}></Route>
              <Route path='cancelapprovetoken' element={<Cancelapprovetoken />}></Route>
              <Route path='cancelapprove721' element={<Cancelapprove721 />}></Route>
              <Route path='cancelapprove1155' element={<Cancelapprove1155 />}></Route>
              <Route path='aboutus' element={<Aboutus />}></Route>
              <Route path='proposaladd' element={<Proposaladd />}></Route>
              <Route path='multisenderrei' element={<Multisenderrei />}></Route>
              <Route path='multisendertoken' element={<Multisendertoken />}></Route>
              <Route path='multisender721' element={<Multisender721 />}></Route>
              <Route path='multisender1155' element={<Multisender1155 />}></Route>
              <Route path='holderscanrei' element={<Holderscanrei />}></Route>
              <Route path='holderscantoken' element={<Holderscantoken />}></Route>
              <Route path='holderscan721' element={<Holderscan721 />}></Route>
              <Route path='holderscan1155' element={<Holderscan1155 />}></Route>
              <Route path='batchcheckbalancerei' element={<Batchcheckbalancerei />}></Route>
              <Route path='batchcheckbalancetoken' element={<Batchcheckbalancetoken />}></Route>
              <Route path='batchcollectionrei' element={<Batchcollectionrei />}></Route>
              <Route path='batchcollectiontoken' element={<Batchcollectiontoken />}></Route>
              <Route path='nftshow721' element={<Nftshow721 />}></Route>
              <Route path='nftshow1155' element={<Nftshow1155 />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </div>
    </HistoryRouter>
  )
}

export default App
